import React from 'react';
import { motion } from 'framer-motion';

const Introduction = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { delay: 0.5, duration: 1.5 } },
        exit: { opacity: 0, transition: { ease: 'easeInOut' } },
    };

    const textVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 1,
                type: 'spring',
                stiffness: 80,
                ease: 'easeInOut',
            },
        },
    };

    return (
        <motion.div
            className="flex items-center justify-center h-screen text-white relative overflow-hidden"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <video
                autoPlay
                muted
                loop
                className="absolute inset-0 object-cover w-full h-full blur-sm"
            >
                <source src="./cod.mp4#t=12,18" type="video/mp4" />
                Seu navegador não suporta o elemento de vídeo.
            </video>

            <motion.div className="text-center relative z-10">
                <motion.h1
                    className="text-6xl font-bold mb-4 animate-pulse"
                    variants={textVariants}
                >
                    Guy Laforgia
                </motion.h1>

                <motion.p
                    className="text-xl"
                    variants={textVariants}
                >
                    Segurança da Informação e Tecnologia da Informação.
                </motion.p>
                <motion.p
                    className="text-xl"
                    variants={textVariants}
                >
                    Explore os detalhes do meu perfil e entre em contato para mais informações.
                </motion.p>
            </motion.div>
        </motion.div>
    );
};

export default Introduction;
