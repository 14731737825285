import React, { useState, useEffect } from 'react';

import BackgroundAnimation from './components/Background/BackgroundAnimation';
import Carousel from './components/Carousel/Carousel';
import Header from './pages/Header';
import About from './pages/About';
import Introduction from './pages/Introduction';

function App() {
  const [showVideo, setShowVideo] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVideo(false);
    }, 8000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showVideo ? (
        <Introduction />
      ) : (
        <><BackgroundAnimation /><Header /><Carousel /><About /></>
      )}
    </div>
  )
}



export default App
