import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Carousel() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentSlide(next),
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const titles = [
        'Monitoração de Infraestrutura NOC',
        'Infraestrutura de TI',
        'OpenSource',
    ];

    const phrases = [
        'Implantação e gereciamento do Zabbix | Implantação e gerenciamento do Grafana | Tenha total controle do que acontece no seu ambiente',
        `Implantação de rede LAN | Configuração de SWITCHS | Rede Wi-Fi | Servidor de Impressão | Servidor de ARQUIVOS | Active Directory | Proxy | Firewall | Backup | Suporte 24x7`,
        'Implantação do GLPI | Implantação do Squid Proxy | Servidor de e-mail Zimbra | Servidor SFTP',
    ];

    return (
        <div className="relative container mx-auto p-8 bg-indigo-950 shadow-lg rounded-lg my-16 w-screen mt-8 flex">
            <Slider {...settings} className="relative w-1/2">
                <div className="relative h-96">
                    <img
                        src="./ti1.jpg"
                        alt="Slide 1"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="relative h-96">
                    <img
                        src="./swt.jpg"
                        alt="Slide 2"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="relative h-96">
                    <img
                        src="./ti3.jpg"
                        alt="Slide 3"
                        className="w-full h-full object-cover"
                    />
                </div>
            </Slider>
            <div className="w-1/2 flex flex-col items-center justify-center bg-indigo-950 text-white">
                <h1 className='px-1 py-1 text-xl font-bold'>
                    {titles[currentSlide]}
                </h1>
                <p className="text-center mt-2 ml-5 px-3 py-1 text-base font-semibold">{phrases[currentSlide]}</p>
            </div>
        </div>
    );
}

export default Carousel;
