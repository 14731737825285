import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus, AiOutlineDownload } from 'react-icons/ai';

function About() {
    const [experiences, setExperiences] = useState([
        {
            title: 'Supervisor de TI na ATMA',
            summary: `Resumo: 
                - Liderei o gerenciamento abrangente de ativos de rede, servidores Windows e Linux, roteadores Mikrotik e soluções de segurança, incluindo PfSense e BitDefender. Responsável pelo gerenciamento de ambientes virtualizados e monitoramento de serviços através de Zabbix e Grafana.
                - Destaco minha expertise em negociações contratuais para otimização de custos, elaboração de projetos de Datacenter e gestão proativa da Segurança da Informação, assegurando conformidade com a LGPD. Estratégias focadas em CyberSecurity e participação ativa na auditoria interna e externa.
                - Atualmente, lidero a gestão do Service Desk, cumprindo SLAs internos em ferramentas de ITSM. Minha trajetória reflete uma ampla gama de habilidades em TI, com ênfase na inovação, eficiência operacional e excelência em serviços`,
            isOpen: false,
        },
        {
            title: 'Analista de infraestrutura de TI',
            summary: 'Resumo: Gerenciamento de toda área de TI desde a implantação, administraçao e melhorias do ambiente.',
            isOpen: false,
        },
        {
            title: 'Responsável de TI - CSC_ITATIBA na Odebrecht',
            summary: 'Resumo: Líder na implantação eficiente da infraestrutura de TI em Itatiba/SP, coordenando projeto abrangente desde 2013. Conquistas incluem gestão de MPLS, configuração de firewall, redes VLAN, servidores, impressoras, suporte a usuários e eficaz gestão de contratos. Concluiu antes do prazo, abaixo do orçamento, seguindo normas e melhores práticas',
            isOpen: false,
        },
        {
            title: 'Analista de Suporte - na Constat Serviços em Informática - Brazil',
            summary: 'Resumo: Líder na implantação eficiente da infraestrutura de TI em Itatiba/SP, coordenando projeto abrangente desde 2013. Conquistas incluem gestão de MPLS, configuração de firewall, redes VLAN, servidores, impressoras, suporte a usuários e eficaz gestão de contratos. Concluiu antes do prazo, abaixo do orçamento, seguindo normas e melhores práticas',
            isOpen: false,
        },
        {
            title: 'Técnico de Infraestrutura na Capgemini',
            summary: 'Resumo: Infraestrutura e suporte crítico a servidores no CCO da companhia GOL LINHAS AÉREAS.',
            isOpen: false,
        },

    ]);

    const handleExperienceClick = (index) => {
        setExperiences((prevExperiences) => {
            const updatedExperiences = prevExperiences.map((experience, i) => ({
                ...experience,
                isOpen: i === index ? !experience.isOpen : false,
            }));
            return updatedExperiences;
        });
    };

    return (
        <div className="relative container mx-auto p-8 bg-indigo-950 shadow-lg rounded-lg my-16 overflow-x-hidden mb-8">
            <div className="flex items-center justify-between mb-6">
                <h1 className="text-4xl font-bold text-white">Sobre Mim</h1>
                <a
                    href="./curriculo.pdf"
                    download="Guy_laforgia_Head_IT.pdf"
                    className="text-indigo-300 hover:text-white hover:font-semibold flex items-center animate-pulse"
                >
                    Baixar Currículo <AiOutlineDownload className="ml-2" />
                </a>
            </div>
            <p className="text-indigo-300 font-semibold mb-4">
                Profissional de TI com mais de 15 anos de experiência, especializado em planejamento,
                implementação e administração de redes, infraestrutura e suporte técnico. Experiente
                líder dedicado ao fortalecimento da eficiência operacional e à entrega de soluções
                tecnológicas de alta qualidade.
            </p>
            <div className="mb-8">
                <h2 className="text-2xl font-bold mb-2 text-white">Histórico Profissional</h2>
                <ul className="list-none list-inside">
                    {experiences.map((experience, index) => (
                        <li key={index} className="mb-2 ">
                            <span
                                className={`font-semibold text-indigo-300 hover:text-white cursor-pointer flex items-center animate-bounce ${experience.isOpen ? 'animate-none' : ''
                                    }`}
                                onClick={() => handleExperienceClick(index)}
                            >
                                <span>{experience.title}</span>
                                {experience.isOpen ? (
                                    <AiOutlineMinus className="ml-2" />
                                ) : (
                                    <AiOutlinePlus className="ml-2" />
                                )}
                            </span>
                            {experience.isOpen && <p className="text-gray-400 ml-6">{experience.summary}</p>}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mb-8">
                <h2 className="text-2xl font-bold mb-2 text-white">Educação</h2>
                <p className="text-indigo-300">Graduação em Redes, Tecnologia da Informação - Impacta Tecnologia</p>
            </div>
            <div className="mb-8">
                <h2 className="text-2xl font-bold mb-2 text-white">Habilidades</h2>
                <ul className="list-disc list-inside text-indigo-300">
                    <li>Hyper-V</li>
                    <li>Windows Azure</li>
                    <li>Gerenciamento de serviços de TI</li>
                    <li>Gestão de Infraestrutura de TI</li>
                    <li>Google Cloud Plataform (GCP)</li>
                    <li>Amazon Web Services</li>
                    <li>Windows Server</li>
                    <li>Linux</li>
                </ul>
            </div>
            <div className="absolute bottom-0 right-0 mb-20 mr-28">
                <img
                    src="./gf2.gif"
                    alt="GIF"
                    className="w-80 h-80 rounded-full"
                />
            </div>
        </div>
    );
}

export default About;
