import React, { useState } from 'react';
import { FaWhatsapp, FaLinkedin, FaInstagram, FaEnvelope } from 'react-icons/fa';

function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    return (
        <header className="relative bg-indigo-950 text-white h-32 flex items-center justify-between px-8 z-10">
            <div className="flex items-center space-x-4">
                <img src="./profile.jpg" alt="Avatar" className="w-20 rounded-full" />
                <div>
                    <h2 className="text-xl font-semibold">Guy Laforgia</h2>
                    <p className="text-sm font-semibold">Segurança da Informação | Head IT | SRE | SysAdmin | Infraestrutura</p>
                </div>
            </div>

            <nav className={`lg:flex lg:space-x-4 lg:items-center ${isMenuOpen ? 'block' : 'hidden'} lg:flex`}>
                <div className="flex items-center space-x-4 absolute right-0 m-8">
                    <span className="text-white font-bold animate-tada">Entre em Contato:</span>
                    <a href="https://wa.me/5511978278664?text=Oi, gostaria de saber mais sobre as consultorias de T.I?" target='_blank' className="text-green-400 hover:text-green-500 mr-2 animate-bounce">
                        <FaWhatsapp size={24} />
                    </a>
                    <a href="https://www.linkedin.com/in/guy-eduardo-laforgia-2b8259b0/" target='_blank' className="text-blue-400 hover:text-blue-500 mr-2 animate-bounce">
                        <FaLinkedin size={24} />
                    </a>
                    <a href="https://www.instagram.com/guybielvivilaforgia/" target='_blank' className="text-pink-400 hover:text-pink-500 mr-2 animate-bounce">
                        <FaInstagram size={24} />
                    </a>
                    <a href="mailto:contato@guylaforgia.com.br" target='_blank' className="text-red-400 hover:text-red-500 animate-bounce">
                        <FaEnvelope size={24} />
                    </a>
                </div>
            </nav>

            <div className="lg:hidden">
                <button
                    onClick={() => setMenuOpen(!isMenuOpen)}
                    className="text-white focus:outline-none"
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
        </header>
    );
}

export default Header;
